<template>
  <v-container
    fluid
    tag="section"
  >
    <!--  -->
    <v-row justify="center">
      <!--  -->
      <v-col
        cols="12"
        md="8"
      >
        <v-card class="mx-auto">
          <v-card-text class="text--primary">
            <div
              v-if="isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <v-col
              v-for="(item, i) in aktifitas.data"
              :key="i"
              cols="12"
            >
              <v-card class="mt-n5">
                <div class="d-flex flex-no-wrap justify-start align-start">
                  <div class="d-flex flex-column align-center">
                    <v-avatar
                      class="ma-3"
                      size="32"
                    >
                      <v-img
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                      />
                    </v-avatar>
                  </div>

                  <div class="d-flex flex-column align-start flex-grow-1">
                    <h1>
                      <v-card-title
                        class="text--primary ml-n3 btn"
                        to="/"
                        v-text="item.user.name"
                      />
                    </h1>
                    <v-card-text
                      class="text--primary mt-n5 ml-n3"
                      v-text="item.post"
                    />
                  </div>
                  <div class="d-flex align-center align-self-sm-center">
                    <v-tooltip
                      bottom
                      color="red"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!--  -->
                        <v-btn
                          icon
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                          @click.prevent="openDialog(item.id)"
                        >
                          <v-icon>
                            mdi-close-octagon
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <template>
                <v-dialog
                  v-model="dialog.open"
                  width="500"
                  :retain-focus="false"
                >
                  <v-card>
                    <v-card-title primary-title>
                      Confirmation
                    </v-card-title>

                    <v-card-text>
                      Anda Yakin Akan Menghapus Aktifitas ?
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                      <v-btn
                        color="primary"
                        @click="dialog.open = false"
                      >
                        BATAL
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        color="error"
                        @click="delActivity(dialog.id)"
                      >
                        HAPUS
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    data () {
      return {
        aktifitas: {
          data: {},
          meta: {},
        },
        meta_aktifitas: {
          next: null,
        },
        dialog: {
          open: false,
          id: 0,
          title: '',
        },
        status_items: ['open', 'close'],
        categoryRules: [value => value !== '' || 'Kategori wajib diisi'],
        isEditing: false,
        isLoading: false,
        post: '',
      }
    },
    mounted () {
      this.getAktifitas()
    },
    methods: {
      openDialog (id) {
        this.dialog.id = id
        this.dialog.open = true
        console.log(this.dialog.id)
      },
      delActivity (id) {
        axios.delete('/v1/user/activity/' + id).then(response => {
          if (response.data.meta.status) {
            this.$toast.error('Berhasil menghapus aktifitas', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
            this.dialog.open = false
          } else {
            console.log(response.data.meta.message)
          }
          // update
          this.getAktifitas()
        })
      },
      setAktifitas (data) {
        this.aktifitas = data
        console.log(this.aktifitas)
      },
      getAktifitas () {
        axios
          .get(
            '/v1/user/activity?entities=user&filter[user_id][is]=' +
              this.$route.params.id +
              '&sort=-id',
          )
          .then(response => {
            this.setAktifitas(response.data)
          })
          .catch(e => {
            console.log(e)
          })
      },
    },
  }
</script>
